@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

body {
  font-family: 'Poppins', sans-serif;
}

img  {
  margin-right: 10px;
  margin-left: 30px;
  max-width: 20px;
  max-height: 20px;
}

.container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  border: 1px solid steelblue;
  padding: 30px;
  border-radius: 5px;
}

.post-container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 200px;
  border: 1px solid steelblue;
  padding: 30px;
  border-radius: 5px;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.post {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.post h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
}

.post p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
}

.liked {
  border: 2px solid orangered;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
  text-align: left;
  margin-left: 5px;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control textarea {
  width: 100%;
  height: 120px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
  resize: none;
}

